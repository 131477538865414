import SolutionsListPage from "@/components/Solutions";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

const Solutions: React.FC = () => {
  return (
    <StaticQuery
      query={graphql`
        query GET_SOLUTION_PAGE_DETAILS {
          contentfulSolutionsPage {
            breadcrumbTitle
            #    #SEO
            seo {
              metaTitle
              metaDescription
              metaUrl
              metaAuthor
              metaKeywords
              metaImage {
                file {
                  url
                }
              }
            }

            #Introduction
            introduction {
              raw
            }
            frequentlyAskedQuestions {
              id
              question
              answer {
                answer
              }
            }

            solutions {
              id
              slug
              title
              icon {
                file {
                  url
                }
              }
              description {
                description
              }
            }

            structuredDataSnippets {
              snippet {
                id
                snippet
              }
            }
          }
        }
      `}
      render={({
        contentfulSolutionsPage: {
          seo,
          introduction,
          frequentlyAskedQuestions,
          solutions,
          structuredDataSnippets,
          breadcrumbTitle,
        },
      }) => (
        <SolutionsListPage
          props={{
            seo,
            introduction,
            frequentlyAskedQuestions,
            solutions,
            structuredDataSnippets,
            breadcrumbTitle,
          }}
        />
      )}
    />
  );
};

export default Solutions;
