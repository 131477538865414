import { embeddedAssetBlockImageUi, embeddedEntryBlockUi } from "@/helpers/richTextEditorUi";
import { SEOType, SolutionCardOptions } from "@/types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";
import Layout from "../App/Layout";
import SEO from "../SEO";
import FrequentlyAskedQuestions from "../Shared/Design/FrequentlyAskedQuestions";
import ServiceCardUI from "../Shared/Design/ServiceCardUi";
import BreadCrumb from "../Shared/extra/BreadCrumb";
import { StructuredDataSnippetTag } from "../Shared/StructuredDataTag";

interface SolutionsListPageProps {
  props: {
    seo: SEOType;
    introduction: any;
    frequentlyAskedQuestions: { question: string; id: string; answer: { answer: string } }[];
    solutions: SolutionCardOptions[];
    structuredDataSnippets: any;
    breadcrumbTitle: string;
  };
}

const SolutionsListPage: React.FC<SolutionsListPageProps> = ({
  props: {
    seo,
    introduction,
    frequentlyAskedQuestions,
    solutions,
    structuredDataSnippets,
    breadcrumbTitle,
  },
}) => {
  const options = {
    renderText: (text) => text.replace("!", "?"),

    renderNode: {
      "embedded-asset-block": (node) => embeddedAssetBlockImageUi(node, introduction),
      "embedded-entry-block": (node) => embeddedEntryBlockUi(node, introduction),
    },
  };

  return (
    <Layout>
      <SEO contentfulSeo={seo} />
      <BreadCrumb
        pageTitle={breadcrumbTitle}
        paths={[
          { path: "/", pathName: "Home" },
          { path: "/solutions/", pathName: "Solutions" },
        ]}
      />

      <StructuredDataSnippetTag snippets={structuredDataSnippets} />

      <section className="services-details-area">
        <br />
        <div className="container mb-4">
          {documentToReactComponents(JSON.parse(introduction?.raw), options)}
        </div>

        <div className="vector-shape6">
          <img src={"/images/shape/vector-shape6.png"} alt="about" />
        </div>
        <br />
        <FrequentlyAskedQuestions list={frequentlyAskedQuestions} />
        <section className="services-area pt-5 pb-5 bg-f1f8fb">
          <div className="container">
            <div className="section-title">
              <h2>{"Get More Solutions for your Business"}</h2>
            </div>
            <div className="row">
              {solutions.map(
                ({
                  id,
                  title,
                  description: { description },
                  slug,
                  icon: {
                    file: { url },
                  },
                }) => {
                  return (
                    <ServiceCardUI
                      key={id}
                      title={title}
                      description={description}
                      slug={"/" + slug}
                      icon={url}
                    />
                  );
                },
              )}
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default SolutionsListPage;
